import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Grid, Divider, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import ActiveAssetsFilterMenu from "components/ActiveAssetsFilterMenu/ActiveAssetsFilterMenu";
import FilterTypes from "components/FilterTypes/FilterTypes";
import PriorityFilter from "components/PriorityFilter/PriorityFilter";
import HighLogo from "images/logos/High.webp";
import LowLogo from "images/logos/Low.webp";
import {
  getActiveAssetsByUser,
  getActiveAssetsFilterCount,
  getAffiliationsAssets,
  getAssetsLegends,
  getRequesterAssets,
} from "api/apiMethods";
import AssetsProgressBarStatus from "components/AssetsProgress/AssetsProgress";
import FilterModal from "components/FilterModal/FilterModal";
import ListItemsLoader from "components/ListItemsLoader/ListItemsLoader";
import Heading from "components/HeadingComponent/Heading";
import DropdownOptionsSort from "components/DropdownOptionsSort/DropdownOptionsSort";

const options = ["Status", "Newest Requests", "Low  Priority", "High Priority", "Oldest Requests"];

function Overview() {
  const [state, setState] = useState({
    open: false,
  });
  const [openRequesterSearch, setOpenRequesterSearch] = useState(false);
  const [openAffiliationSearch, setOpenAffiliationSearch] = useState(false);
  const [selectedSortValues, setSelectedSortValues] = useState(
    JSON.parse(sessionStorage.getItem("selectedSort")) || []
  );
  const [selectedStatus, setSelectedStatus] = useState(
    JSON.parse(sessionStorage.getItem("selectedStatus")) || []
  );
  const [selectedRequestTypes, setSelectedRequestTypes] = useState(
    JSON.parse(sessionStorage.getItem("selectedRequestTypes")) || []
  );
  const [selectedApprovalLevels, setSelectedApprovalLevels] = useState(
    JSON.parse(sessionStorage.getItem("selectedApprovalLevels")) || []
  );
  const [selectedPartner, setSelectedPartner] = useState(
    JSON.parse(sessionStorage.getItem("selectedPartners")) || []
  );
  const [selectedRequester, setSelectedRequester] = useState(
    JSON.parse(sessionStorage.getItem("selectedRequester")) || []
  );
  const [filterNames, setFilterNames] = useState(
    JSON.parse(sessionStorage.getItem("selectedFilters")) || []
  );
  const [allFilterNamesModal, setAllFilterNamesModal] = useState(
    JSON.parse(sessionStorage.getItem("selectedAllFiltersModal")) || []
  );
  const [activeAssets, setActiveAssets] = useState([]);
  const [legendData, setLegendData] = useState([]);
  const [partnerDetails, setPartnerDetails] = useState(null);
  const [filternamesPartner, setfilternamesPartner] = useState([]);
  const [filternamesRequester, setfilternamesRequester] = useState([]);
  const [requesterDetails, setRequesterDetails] = useState(null);
  const [filterColumnList, setFilterColumnList] = useState([]);
  const [activeAssetsFilterCount, setActiveAssetsFilterCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isOpenSort, setIsOpenSort] = useState(false);

  const [filteredAffiliationArray, setFilteredAffiliationArray] = useState();
  const [filteredRequesterArray, setFilteredRequesterArray] = useState();

  const userId = localStorage.getItem("user_id");

  const handleClickSort = () => {
    setIsOpenSort((prevState) => !prevState);
  };

  const activeAssetsDataObj = {
    statusIds: selectedStatus.join(":"),
    requestTypes: selectedRequestTypes.join(":"),
    approvalLevels: selectedApprovalLevels.join(":"),
    partners: selectedPartner.join(":"),
    requester: selectedRequester.join(":"),
    userId: Number(userId),
    sort: selectedSortValues.join(","),
  };

  const toggleSelectionAffiliationsCheckbox = (event) => {
    const filterlist = [...filternamesPartner];
    if (event.target.checked) {
      setSelectedPartner((prevState) => {
        return prevState.concat(event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.concat(event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.concat(event.target.value);
      });
      filterlist.push(event.target.value);
      setFilteredAffiliationArray(
        filteredAffiliationArray.filter((item) => item.name !== event.target.value)
      );
      setfilternamesPartner(filterlist);
    } else {
      filterlist.filter((item) => item !== event.target.value);
      // filteredAffiliationArray.push({ id: Math.random(1, 10), name: event.target.value });
      setfilternamesPartner(filterlist);
      setSelectedPartner((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
    }
  };

  const toggleSelectionRequesterCheckbox = (event) => {
    const filterlist = [...filternamesRequester];
    if (event.target.checked) {
      setSelectedRequester((prevState) => {
        return prevState.concat(event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.concat(event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.concat(event.target.value);
      });
      setFilteredRequesterArray(
        filteredRequesterArray.filter((item) => item.name !== event.target.value)
      );
      filterlist.push(event.target.value);
      setfilternamesRequester(filterlist);
    } else {
      filterlist.filter((item) => item !== event.target.value);

      filteredRequesterArray.push({ id: Math.random(1, 10), name: event.target.value });
      setfilternamesRequester(filterlist);
      setSelectedRequester((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setFilterNames((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
      setAllFilterNamesModal((prevState) => {
        return prevState.filter((a) => a !== event.target.value);
      });
    }
  };

  sessionStorage.setItem("selectedFilters", JSON.stringify(filterNames));
  sessionStorage.setItem("selectedAllFiltersModal", JSON.stringify(allFilterNamesModal));
  sessionStorage.setItem("selectedStatus", JSON.stringify(selectedStatus));
  sessionStorage.setItem("selectedRequestTypes", JSON.stringify(selectedRequestTypes));
  sessionStorage.setItem("selectedApprovalLevels", JSON.stringify(selectedApprovalLevels));
  sessionStorage.setItem("selectedRequester", JSON.stringify(selectedRequester));
  sessionStorage.setItem("selectedPartners", JSON.stringify(selectedPartner));
  sessionStorage.setItem("selectedSort", JSON.stringify(selectedSortValues));

  const handleClickOpen = () => {
    setState({ open: true });
  };

  const handleClose = () => {
    setState({ open: false });
    setLoading(true);
    getActiveAssetsByUser(activeAssetsDataObj).then((response) => {
      setLoading(false);
      if (response !== null) {
        setActiveAssets(response.data);
        // console.log("ACTIVE ASSETS", response.data);
      }
    });
  };

  const onClickCrossButton = () => {
    setState({ open: false });
    setSelectedStatus([]);
    setSelectedRequestTypes([]);
    setSelectedApprovalLevels([]);
    setFilterColumnList([]);
    setSelectedPartner([]);
    setSelectedRequester([]);

    sessionStorage.removeItem("selectedFilters");
    sessionStorage.removeItem("selectedAllFiltersModal");
    sessionStorage.removeItem("selectedStatus");
    sessionStorage.removeItem("selectedRequestTypes");
    sessionStorage.removeItem("selectedApprovalLevels");
    sessionStorage.removeItem("selectedRequester");
    sessionStorage.removeItem("selectedPartners");

    const filterNameList = [...filterNames];
    const filterListName = filterNameList.filter((item) => {
      const isexistItem = allFilterNamesModal.find((items) => items === item);
      if (isexistItem) {
        return item !== isexistItem;
      }
      return item;
    });
    setFilterNames(filterListName);
    setAllFilterNamesModal([]);
  };

  const handleClickRequester = () => {
    setOpenRequesterSearch((prevState) => !prevState);
  };

  const handleClickAffiliation = () => {
    setOpenAffiliationSearch((prevState) => !prevState);
  };

  const lowPriority = () => {
    setSelectedSortValues(["Low Priority"]);
    setFilterNames((prevState) => {
      return prevState.filter(
        (a) =>
          a !== "Status" &&
          a !== "Newest Requests" &&
          a !== "Oldest Requests" &&
          a !== "High Priority" &&
          a !== "Low Priority"
      );
    });
    setFilterNames((prevState) => {
      return prevState.concat("Low Priority");
    });
  };

  const highPriority = () => {
    setSelectedSortValues(["High Priority"]);
    setFilterNames((prevState) => {
      return prevState.filter(
        (a) =>
          a !== "Status" &&
          a !== "Newest Requests" &&
          a !== "Oldest Requests" &&
          a !== "High Priority" &&
          a !== "Low Priority"
      );
    });
    setFilterNames((prevState) => {
      return prevState.concat("High Priority");
    });
  };

  const filterRemove = (event, filter) => {
    event.preventDefault();
    setFilterNames((prevState) => {
      return prevState.filter((a) => {
        if (typeof filter === "object") {
          return a.wrike_status_id !== filter.wrike_status_id;
        }
        return a !== filter;
      });
    });

    setAllFilterNamesModal((prevState) => {
      return prevState.filter((a) => {
        if (typeof filter === "object") {
          return a.wrike_status_id !== filter.wrike_status_id;
        }
        return a !== filter;
      });
    });

    setSelectedSortValues((prevState) => {
      return prevState.filter((a) => a !== filter);
    });

    setSelectedPartner((prevState) => {
      return prevState.filter((a) => a !== filter);
    });

    setSelectedRequester((prevState) => {
      return prevState.filter((a) => a !== filter);
    });

    setSelectedRequestTypes((prevState) => {
      return prevState.filter((a) => a !== filter);
    });

    setSelectedApprovalLevels((prevState) => {
      return prevState.filter((a) => a !== filter);
    });

    setSelectedStatus((prevState) => {
      return prevState.filter((a) => a !== filter.wrike_status_id);
    });
  };

  const breadcrumbs = {
    previousPageUrlTitle: "Active Assets",
    previousPageBackUrl: "/home",
    nextPageUrlTitle: "Assets Details",
    nexPagetUrl: "",
  };

  useEffect(() => {
    if (partnerDetails == null) {
      getAffiliationsAssets(userId).then((response) => {
        if (response !== false) {
          setPartnerDetails(response);
          setFilteredAffiliationArray(response);
        }
      });
    }

    if (requesterDetails == null) {
      getRequesterAssets().then((response) => {
        if (response !== false) {
          setRequesterDetails(response);
          setFilteredRequesterArray(response);
        }
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    getActiveAssetsByUser(activeAssetsDataObj).then((response) => {
      setLoading(false);
      if (response !== null) {
        setActiveAssets(response.data);
      }
    });
  }, [state, selectedSortValues]);

  useEffect(() => {
    setLoading(true);
    getActiveAssetsFilterCount(activeAssetsDataObj).then((response) => {
      setLoading(false);
      if (response !== null) {
        setActiveAssetsFilterCount(response.count);
      }
    });
  }, [
    selectedStatus,
    selectedRequestTypes,
    selectedRequester,
    selectedPartner,
    selectedApprovalLevels,
  ]);

  useEffect(() => {
    getAssetsLegends(userId).then((response) => {
      if (response !== null) {
        setLegendData(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (allFilterNamesModal.length && activeAssets.length === 0) {
      setLoading(false);
    }
  }, []);

  const renderList = ({ index, key, style }) => {
    const data = activeAssets[index];
    let percent;

    if (data.days_till_today >= 1.5 * data.total_days) {
      percent = 100;
    } else if (data.days_till_today > data.total_days) {
      const newP = data.days_till_today / data.total_days;
      percent = 45 + newP * 15;
    } else if (data.days_till_today < data.total_days) {
      percent = (data.days_till_today / data.total_days) * 59;
    } else if (data.days_till_today === data.total_days) {
      percent = 58.5;
    }

    return (
      <div key={key} style={style} className="detail-view-container__progress-bar-top-space">
        <AssetsProgressBarStatus
          title={data.custom_status_label}
          description={data.status_description}
          barColor={data.color}
          barValue={data.days_till_today_width_percent}
          taskPriority={data.task_importance}
          data={data}
          breadcrumbsDetail={breadcrumbs}
          curentPageLink="/home/assetdetail"
          percent={percent}
          previous_status_percent={data.previous_status_percent}
        />
      </div>
    );
  };

  const listingData = () => {
    if (activeAssets?.length <= 0) {
      return (
        <Heading
          title="No Record Available"
          titleStyle="detail-view-container__no-assets-heading"
        />
      );
    }
    return (
      <ListItemsLoader
        activeAssets={activeAssets}
        renderData={renderList}
        itemSize={55}
        width="100%"
        height={455}
      />
    );
  };

  return (
    <Container pr={0} pl={0} sx={{ position: "relative" }}>
      <Grid container>
        <Grid item md={6} lg={4} sm={12}>
          <Typography className="detail-view-container__typography-assets-heading" variant="body">
            {activeAssets !== null ? activeAssets?.length : ""} Active Asset
            {activeAssets !== null && activeAssets?.length === 1 ? "" : "s"}
          </Typography>
        </Grid>

        <Grid item md={6} lg={8} sm={12}>
          <Grid
            container
            spacing={2.8}
            columns={{ md: 3 }}
            className="detail-view-container__align-grid">
            {legendData.map((items) => {
              let tooltip = "0";
              if (items.name === "Intake") {
                tooltip = (
                  <Typography fontFamily="GothamBook" fontSize={12}>
                    <Typography fontFamily="GothamBook" fontSize={14} fontWeight="bold">
                      Intake
                    </Typography>
                    The <b>Intake</b> main status indicates that the request is in one of the
                    following initial processing statuses:
                    <Typography fontFamily="GothamBook" fontSize={12} fontWeight="bold">
                      Intake New Request, Pending Partner Intake Info, Received From Partner or On
                      Hold.
                    </Typography>
                  </Typography>
                );
              } else if (items.name === "Under Approval") {
                tooltip = (
                  <Typography fontFamily="GothamBook" fontSize={12}>
                    <Typography fontFamily="GothamBook" fontSize={14} fontWeight="bold">
                      Under Approval
                    </Typography>
                    The <b>Under Approval</b> main status indicates that the request is in one of
                    the following review statuses:
                    <Typography fontFamily="GothamBook" fontSize={12} fontWeight="bold">
                      Wondros Review, Wondros Prep for NIH, NIH Comms Review, Program Officer
                      Review, NIH Comms Final Review, Spanish Committee, SAGE, IRB, NIH Language
                      Proofing, or Partner Info.
                    </Typography>
                  </Typography>
                );
              } else if (items.name === "In Production") {
                tooltip = (
                  <Typography fontFamily="GothamBook" fontSize={12}>
                    <Typography fontFamily="GothamBook" fontSize={14} fontWeight="bold">
                      In Production
                    </Typography>
                    The <b>In Production</b> main status indicates that the request is in the
                    following production or review statuses:
                    <Typography fontFamily="GothamBook" fontSize={12} fontWeight="bold">
                      Design Production, Pending Partner Info, Spanish Review or Partner Review.
                    </Typography>
                  </Typography>
                );
              } else if (items.name === "Prep Final Files") {
                tooltip = (
                  <Typography fontFamily="GothamBook" fontSize={12}>
                    <Typography fontFamily="GothamBook" fontSize={14} fontWeight="bold">
                      Prep Final Files
                    </Typography>
                    The <b>Prep Final Files</b> main status indicates that the request is in the
                    following final statuses:
                    <Typography fontFamily="GothamBook" fontSize={12} fontWeight="bold">
                      Final File Production or Asset QC, Programming, Upload.
                    </Typography>
                  </Typography>
                );
              }
              return (
                <Grid item>
                  <ActiveAssetsFilterMenu
                    dotColor={items.color}
                    title={items.name}
                    tooltip={tooltip}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      {/* Selected Filters */}
      <Grid container pt={1.25} pb={1.1875}>
        <Grid item md={12} className="filtersNamesContainer">
          {filterNames !== undefined &&
            filterNames.map((filter, index) => (
              <span className="detail-view-container__detail-view-asset-names" id="asset2">
                <Typography
                  className="detail-view-container__typography-font-filter"
                  pl={1}
                  pt={0.5}
                  pb={0.5}
                  pr={1.25}>
                  {filter?.name || filter}
                </Typography>
                <CloseIcon
                  sx={{ width: "20px", height: "20px" }}
                  id={index}
                  onClick={(event) => filterRemove(event, filter)}
                  className="detail-view-container__detail-view-close-icon"
                />
              </span>
            ))}
        </Grid>
      </Grid>

      {/* current status menu */}
      <Grid
        container
        sx={{
          width: "100%",
        }}>
        <Grid item md={4} lg={2.1} sm={12} xs={12} pt="19px">
          <Typography variant="h7" className="detail-view-container__typography-current-status">
            CURRENT STATUS
          </Typography>
        </Grid>

        <Grid item md={4} lg={3.9} pt={1.7} sm={12} xs={12}>
          <Grid container sx={{ gridColumnStart: 2, marginLeft: 2.5 }}>
            <PriorityFilter
              title="High Priority"
              titleStyle="detail-view-container__img-logo-image-high"
              image={HighLogo}
              onClick={highPriority}
              leftSpace={2.7}
            />
            <PriorityFilter
              titleStyle="detail-view-container__img-logo-image"
              title="Low Priority"
              image={LowLogo}
              onClick={lowPriority}
            />
          </Grid>
        </Grid>

        <Grid item md={4} lg={6}>
          <Grid container className="detail-view-container__align-grid" mt={3}>
            <FilterTypes
              gridVal={allFilterNamesModal.length === 0 ? { lg: 4 } : { lg: 4 }}
              title="FILTER"
              onClick={handleClickOpen}
              filter
              noOfSelectedFIlters={allFilterNamesModal.length}
            />
            <Grid className="detail-view-container__grid_sort-border" md={0.22} />

            <DropdownOptionsSort
              selectedSortValues={selectedSortValues}
              setSelectedSortValues={setSelectedSortValues}
              setFilterNames={setFilterNames}
              data={options}
              handleClickSort={handleClickSort}
              isOpenSort={isOpenSort}
              setIsOpenSort={setIsOpenSort}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ width: "100%", height: "1px", marginBottom: 0 }} />
      {/* </Box> */}

      {/* Filter Modal */}
      <FilterModal
        openModal={state.open}
        handleClose={handleClose}
        handleClickAffiliation={handleClickAffiliation}
        handleClickRequester={handleClickRequester}
        selectedStatus={selectedStatus}
        selectedRequestTypes={selectedRequestTypes}
        selectedApprovalLevels={selectedApprovalLevels}
        filterNames={filterNames}
        setSelectedStatus={setSelectedStatus}
        setFilterNames={setFilterNames}
        setSelectedRequestTypes={setSelectedRequestTypes}
        setSelectedApprovalLevels={setSelectedApprovalLevels}
        selectedPartner={selectedPartner}
        setSelectedPartner={setSelectedPartner}
        selectedRequester={selectedRequester}
        setSelectedRequester={setSelectedRequester}
        numberOfSelectedAssets={activeAssets?.length}
        setPartnerDetails={setPartnerDetails}
        setRequesterDetails={setRequesterDetails}
        requesterDetails={requesterDetails}
        partnerDetails={partnerDetails}
        onClickAffiliationCheckBox={toggleSelectionAffiliationsCheckbox}
        onClickRequesterCheckBox={toggleSelectionRequesterCheckbox}
        allFilterNamesModal={allFilterNamesModal}
        setAllFilterNamesModal={setAllFilterNamesModal}
        filteredAffiliationArray={filteredAffiliationArray}
        filteredRequesterArray={filteredRequesterArray}
        filterColumnList={filterColumnList}
        setFilterColumnList={setFilterColumnList}
        onClickCrossButton={onClickCrossButton}
        openRequesterSearch={openRequesterSearch}
        openAffiliationSearch={openAffiliationSearch}
        filternamesRequester={filternamesRequester}
        setfilternamesRequester={setfilternamesRequester}
        activeAssetsFilterCount={activeAssetsFilterCount}
      />

      {(loading || (activeAssets.length <= 0 && allFilterNamesModal.length <= 0)) && (
        <CircularProgress
          size={100}
          sx={{
            color: "#262262",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
            zIndex: 20,
          }}
        />
      )}
      {/* Detail Body */}
      <Box sx={{ maxHeight: 450 }}>
        <Grid container>{listingData()}</Grid>
      </Box>

      <Grid container justifyContent="center">
        <Grid item md={1} mt={1.5} />
      </Grid>
    </Container>
  );
}

export default Overview;
